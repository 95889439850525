import React, { useEffect, useState } from 'react'
import HeaderComponent from '../components/headerComponent'
import FooterComponent from '../components/footerComponent';

const FaqPage = () => {

    const [faqIdOpen, setFaqIdOpen] = useState(null);
    const [faq, setFaq] = useState([]);

    const getFaq = async () => {
        const response = await fetch(process.env.REACT_APP_DIRECTUS_URL + 'items/faq', {referrerPolicy: "unsafe-url" });
        const data = await response.json();
        setFaq(data.data);
        console.log(data.data)
    }

    useEffect(() => {
        getFaq()
    }, []);



  return (
    <>
        <HeaderComponent />
        <div className='bg-grey'>
            <div class="container mx-auto">
                <h1 class="font-semibold text-4xl py-12">Foire aux questions</h1>
                <div className="py-2">
                {faq.map((item, index) => (
                    <div className='px-6 py-4 bg-white my-4' onClick={() => setFaqIdOpen(index)}>
                        <div class="flex flex-row items-center justify-between">
                            <div>
                                <h6 className='text-xl font-semibold'>{item.name}</h6>
                            </div>
                            <div className='flex justify-end'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.17654 9.13207L11.8335 5.47507C11.9001 5.40198 11.9806 5.34299 12.0704 5.30159C12.1602 5.26018 12.2573 5.23721 12.3561 5.23403C12.4549 5.23084 12.5534 5.24751 12.6456 5.28305C12.7378 5.31859 12.822 5.37227 12.8931 5.44092C12.9643 5.50957 13.0209 5.59179 13.0597 5.68272C13.0985 5.77364 13.1186 5.87143 13.1189 5.97028C13.1192 6.06913 13.0997 6.16704 13.0615 6.25821C13.0233 6.34938 12.9672 6.43197 12.8965 6.50107L12.8785 6.51907L8.69954 10.6991C8.56095 10.8376 8.37301 10.9155 8.17704 10.9155C7.98107 10.9155 7.79312 10.8376 7.65454 10.6991L3.47554 6.52007C3.40574 6.45264 3.34991 6.37212 3.31123 6.2831C3.27255 6.19409 3.25178 6.09834 3.25011 6.0013C3.24844 5.90426 3.2659 5.80784 3.30149 5.71755C3.33708 5.62726 3.3901 5.54487 3.45754 5.47507C3.52497 5.40527 3.60549 5.34944 3.6945 5.31076C3.78351 5.27208 3.87927 5.25132 3.97631 5.24964C4.07335 5.24797 4.16976 5.26543 4.26005 5.30102C4.35034 5.33661 4.43274 5.38964 4.50254 5.45707L4.52054 5.47507L8.17654 9.13207Z" fill="#6F1DF4" stroke="#6F1DF4" stroke-width="0.5"/>
                                </svg>
                            </div>
                        </div>
                        
                        {
                            faqIdOpen == index ? 
                            <p className='py-4' dangerouslySetInnerHTML={{ __html: item.text_faq }}></p>
                            : null
                        }
                    </div>
                ))}
                </div>
            </div>
        </div>
        <FooterComponent />
    </>
  )
}

export default FaqPage