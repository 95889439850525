import React, { useEffect, useState } from 'react'
import '../components/headerComponent'
import HeaderComponent from '../components/headerComponent';
import { Carousel } from 'react-responsive-carousel';
import api, { fetchData } from '../utils/fetch';
import ProductCardComponent from '../components/productCardComponent';
import { Link } from 'react-router-dom';
import {useProductsCategoryName} from '../hooks/usePrestashopProducts';
import SliderProductComponent from '../components/sliderProductsComponent';
import LoadingComponent from '../components/loadingComponent';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { getImage } from '../utils/someFunctions';
import FooterComponent from '../components/footerComponent';

function HomePage() {

    const [sliders, setSliders] = useState([]);
    const [articles, setArticles] = useState([]);
    const [partenaires, setPartenaires] = useState([
        'Partenaire-Epson.png',
        'Partenaire-Context.png',
        'chatel.png',
        'Applify.png',
        'Canon-Partenaire.png',
        'Partenaire-Gera.png',
    ]);

    const getArticles = async () => {
        const response = await fetch(process.env.REACT_APP_DIRECTUS_URL + 'items/blog', {referrerPolicy: "unsafe-url" });
        const data = await response.json();
        setArticles(data.data);
        console.log(data.data)
    }

    useEffect(() => {
        getArticles()
    }, []);

    // const { productByCat, error, loading } = useProductCategorie('879');
    const { products, error, loading } = useProductsCategoryName('produit populaire');
    const { products: productsPromo, error: errorPromo, loading: loadingPromo } = useProductsCategoryName('promo', 10);
    const configSlider = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
      };

    const getSliders = async () => {
        const response = await fetch(process.env.REACT_APP_DIRECTUS_URL + 'items/sliders');
        const data = await response.json();

        console.log(data.data)
        setSliders(data.data);
    }

    useEffect(() => {
        getSliders()
    }, []);
    
  return (
    <>
        <HeaderComponent />

        <div className='container mx-auto'>
            <div className="py-6 mobile:hidden">
                <Slider {...configSlider} >

                        {sliders.map((slider) => (
                        <div>
                            <div className='h-[400px] w-full rounded-xl flex items-center' style={{
                                    // backgroundColor: slider.image_desktop,
                                    // backgroundImage: slider.image_desktop ? `url(${process.env.REACT_APP_DIRECTUS_URL + 'assets/'+slider.image_desktop})` : '',
                                    // backgroundSize: 'cover',
                                }}>
                                <img src={process.env.REACT_APP_DIRECTUS_URL + 'assets/'+slider.image_desktop} className='rounded-xl' alt="" />
                                <p className='' dangerouslySetInnerHTML={{ __html: slider.text_slider }}></p>
                            </div>
                        </div>
                        ))}
                    
                </Slider>
            </div>
            <div className="py-6 md:hidden">
                <Slider {...configSlider} >

                        {sliders.map((slider) => (
                        <div>
                            <div className='h-[400px] w-full rounded-xl flex items-center' style={{
                                    // backgroundColor: slider.image_desktop,
                                    // backgroundImage: slider.image_desktop ? `url(${process.env.REACT_APP_DIRECTUS_URL + 'assets/'+slider.image_desktop})` : '',
                                    // backgroundSize: 'cover',
                                }}>
                                <img src={process.env.REACT_APP_DIRECTUS_URL + 'assets/'+slider.image_mobile} className='rounded-xl' alt="" />
                                <p className='' dangerouslySetInnerHTML={{ __html: slider.text_slider }}></p>
                            </div>
                        </div>
                        ))}
                    
                </Slider>
            </div>
            <div className='text-center py-6'>
                <h1 className='font-bold text-xl md:text-2xl py-2'>La Boutique du Traceur,</h1>
                <h2 className='text-xl md:text-2xl'>Partenaires agréé des plus grandes marques HP, Canon, Epson.</h2>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-4 gap-4 border-grey border rounded-lg md:py-6'>
                <div className='flex items-center md:justify-center py-3 px-6 md:py-0 md:px-0'>
                    <div><img src="/images/icon-garantie.png" alt="" /></div>
                    <div className='pl-3 text-left'>
                        <p className='font-bold text-xl'>Garantie</p>
                        <p>jusqu’à 5 ans</p>
                    </div>
                </div>
                <div className='flex items-center md:justify-center py-3 px-6 md:py-0 md:px-0'>
                    <div><img src="/images/icon-leasing.png" alt="" /></div>
                    <div className='pl-3 text-left'>
                        <p className='font-bold text-xl'>Solution de leasing</p>
                        <p>6 à 64 mois</p>
                    </div>
                </div>
                <div className='flex items-center md:justify-center py-3 px-6 md:py-0 md:px-0'>
                    <div><img src="/images/icon-delivery.png" alt="" /></div>
                    <div className='pl-3 text-left'>
                        <p className='font-bold text-xl'>Livraison en Europe</p>
                        <p>Multi-transporteur</p>
                    </div>
                </div>
                <div className='flex items-center md:justify-center py-3 px-6 md:py-0 md:px-0'>
                    <div><img src="/images/icon-promo.png" alt="" /></div>
                    <div className='pl-3 text-left'>
                        <p className='font-bold text-xl'>Prix attractifs</p>
                        <p>leader du marché</p>
                    </div>
                </div>
                
            </div>
            <div className='grid md:gap-2 md:grid-cols-2 py-12'>
                <div className='text-center md:text-left font-semibold text-2xl'><h3>Meilleurs ventes</h3></div>
                <div className='text-right py-2 md:py-0'>Tous les produits</div>
            </div>
            <div className="pb-6">
                {
                    loading ? <LoadingComponent /> : <SliderProductComponent products={products} />
                }
            </div>

        </div>
        <div className='bg-grey'>
            <div className='container mx-auto'>
                <div className="py-4 md:py-24 md:flex md:flex-row">
                    <div style={{'backgroundImage': `url(${getImage('categorie-cartouches.png')})`}} className="md:w-2/6 h-64 md:h-[37rem] rounded-lg bg-cover bg-center bg-[image:var(--image-url-categorie-cartouches)] flex flex-col justify-end p-4">
                        <h3 className='font-semibold text-white'>Cartouches d’encre</h3>
                        <Link className='font-medium text-white' to={'/359-cartouches-d-encre-imprimante-hp-designjet-canon-ipf-epson'}>Voir les produits</Link>
                    </div>
                    <div className="md:w-4/6">
                        <div style={{'backgroundImage': `url(${getImage('categorie-rouleau-papier.png')})`}}  className="mt-4 md:mt-0 md:ml-4 rounded-lg h-32 md:h-72 bg-cover bg-center bg-[image:var(--image-url-categorie-rouleau-papier)] flex flex-col items-end justify-end p-4">
                            <h3 className='font-semibold'>Rouleaux de papiers</h3>
                            <Link className='font-medium text-black' to={'/361-papiers'}>Voir les produits</Link>
                        </div>
                        <div className="flex flex-row pt-4 md:pl-4">
                            <div style={{'backgroundImage': `url(${getImage('categorie-tete-impression.png')})`}}  className="w-1/2 h-32 md:h-72 bg-cover bg-center bg-[image:var(--image-url-categorie-tete-impression)] flex flex-col justify-end p-4">
                                <h3 className='font-semibold'>Têtes d’impression</h3>
                                <Link className='font-medium text-black' to={'/359-cartouches-d-encre-imprimante-hp-designjet-canon-ipf-epson'}>Voir les produits</Link>
                            </div>
                            <div style={{'backgroundImage': `url(${getImage('categorie-accessoire.png')})`}}  className="w-1/2 ml-4 h-32 md:h-72 bg-cover bg-center rounded-lg bg-[image:var(--image-url-accessoire)] flex flex-col justify-end p-4">
                                <h3 className='font-semibold'>Accessoires</h3>
                                <Link className='font-medium text-black' to={'/359-cartouches-d-encre-imprimante-hp-designjet-canon-ipf-epson'}>Voir les produits</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container mx-auto'>
            <div className='grid grid-cols-2 mobile:grid-cols-1'>
                <div className='text-center md:text-left font-semibold text-2xl'><h3>Produits en promotion</h3></div>
                <div className='flex justify-end py-2 md:py-0'>
                    <Link to='/promotions' className='flex flex-row items-center'>
                    Tous les produits <svg className='ml-4' width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.53329 7.68666L4.27329 4.94666C4.39746 4.82175 4.46716 4.65279 4.46716 4.47666C4.46716 4.30054 4.39746 4.13157 4.27329 4.00666L1.60663 1.34" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="bevel"/>
                    </svg>
                    </Link>
                </div>
            </div>
            <div className="pb-6">
                {
                    loading ? <LoadingComponent /> : <SliderProductComponent products={productsPromo} />
                }
            </div>
            <div class="grid grid-cols-3 mobile:grid-cols-1 py-12">
                <div className='mobile:hidden'></div>
                <div class=""><h3 className='text-center font-semibold text-2xl'>Les dernières actualités</h3></div>
                <div class="flex justify-end mobile:py-2">
                    <Link to='/blog' className='flex flex-row items-center'>
                    Tous les articles <svg className='ml-4' width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.53329 7.68666L4.27329 4.94666C4.39746 4.82175 4.46716 4.65279 4.46716 4.47666C4.46716 4.30054 4.39746 4.13157 4.27329 4.00666L1.60663 1.34" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="bevel"/>
                    </svg>
                    </Link>
                </div>
            </div>
            <div className='grid grid-cols-3 mobile:grid-cols-1 gap-6 py-12'>
                <div>
            {
                articles && articles[0] && (
                    <div className='h-[400px] rounded-lg p-4 relative' style={{
                        backgroundImage: `url(${process.env.REACT_APP_DIRECTUS_URL + 'assets/' + articles[0].image_presentation})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: '#f1f1f1'
                    }}>
                        {console.log(articles)}
                        <div className='absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 rounded-lg p-8 flex justify-end flex-col '>
                            <Link to={'/blog/' + articles[0].id} className='text-white underline text-xl font-semibold'>{articles[0].article_name}</Link>
                            <div className='text-white py-2 text-xs'>{
                            (() => {
                                const date = new Date(articles[0].date_created);
                                const day = date.getDate().toString().padStart(2, '0');
                                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
                                const year = date.getFullYear();
                                return `Date ${day}.${month}.${year}`;
                            })()
                            }</div>
                        </div>
                    </div>
                    
                )
            }
            </div>
            <div>
            {
                articles && articles[1] && (
                    <div className=''>
                        <div className='h-[200px] rounded-lg' style={{
                            backgroundImage: `url(${process.env.REACT_APP_DIRECTUS_URL + 'assets/'+articles[1].image_presentation})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: '#f1f1f1'
                        }}></div>
                        <p className=''></p>
                        <div class="py-6">
                            <Link to={'/blog/'+articles[1].id} className='font-semibold text-lg py-6 underline'>{articles[1].article_name}</Link>
                            <div className='py-2 text-xs'>{
                            (() => {
                                const date = new Date(articles[0].date_created);
                                const day = date.getDate().toString().padStart(2, '0');
                                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
                                const year = date.getFullYear();
                                return `Date ${day}.${month}.${year}`;
                            })()
                            }</div>
                        </div>
                    </div>
                )
            }
            </div>
            <div>
            {articles && articles.slice(2, 5).map((article) => (
                <div className='flex flex-row h-[100px] mobile:py-3'>
                    <div class="w-2/3">
                        <Link to={'/blog/'+article.id} className='font-semibold text-lg py-6 underline'>{article.article_name}</Link>
                        <div className='py-2 text-xs'>{
                            (() => {
                                const date = new Date(articles[0].date_created);
                                const day = date.getDate().toString().padStart(2, '0');
                                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
                                const year = date.getFullYear();
                                return `Date ${day}.${month}.${year}`;
                            })()
                        }</div>
                    </div>
                    <div class="w-1/3 mobile:flex mobile:justify-end">
                    <img src={process.env.REACT_APP_DIRECTUS_URL + 'assets/'+article.image_presentation} alt="" />
                    </div>
                </div>
            ))}
            </div>
            </div>
        </div>
        <div class="bg-grey py-12">
            <div class="container mx-auto">
                <div class="py-6"><h3 className='text-center font-semibold text-2xl'>Nos partenaires</h3></div>
                <div className='grid grid-cols-6 mobile:grid-cols-3 py-4 gap-2'>
                    {
                        partenaires && partenaires.map((partenaire) => (
                            <div class="flex justify-center items-center">
                                <img src={getImage(partenaire)} alt="" />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
        <div className='container mx-auto py-12'>
            <div class="flex flex-row mobile:flex-col">
                <div class="w-1/3 mobile:w-full bg-grey p-6">
                    <h4 className='text-xl font-semibold'>Nous contacter</h4>
                    <p className='text-sm py-4'>Nos service client est disponible pour toute vos questions </p>
                    <div className='flex flex-row items-center py-2'>
                        <div><img src={getImage('Calendar.png')} alt=""/></div>
                        <div className='pl-4'>
                            <p>Du lundi au vendredi</p>
                            <p className='font-bold'>9:00 - 18:00</p>
                        </div>
                    </div>
                    <div className='flex flex-row items-center py-2'>
                        <div><img src={getImage('Smartphone.png')} alt=""/></div>
                        <div className='pl-4'>
                            <p>Téléphone</p>
                            <a href='tel:0 805 130 013' className='font-bold'>0 805 130 013 <span className='font-light text-xs'>(Appel gratuit)</span> </a>
                        </div>
                    </div>
                    <div className='flex flex-row items-center py-2'>
                        <div><img src={getImage('Location.png')} alt=""/></div>
                        <div className='pl-4'>
                            <p>Adresse :</p>
                            <p className='font-bold'>13 Rue Georges Auric, 75019 Paris</p>
                        </div>
                    </div>
                    <div className='flex flex-row items-center py-2'>
                        <div><img src={getImage('Location.png')} alt=""/></div>
                        <div className='pl-4'>
                            <p>Email :</p>
                            <a href='mailto:contact@laboutiquedutraceur.fr' className='font-bold'>contact@laboutiquedutraceur.fr</a>
                        </div>
                    </div>
                </div>
                <div class="w-2/3 mobile:w-full">
                    {/* <img className='w-full h-full object-cover' src={getImage('Map.png')} alt=""/> */}
                    <div>
                        <div id="gmap-canvas" className='w-full'>
                            <iframe className='w-full h-[400px]' frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=13+Rue+Georges+Auric,+75019+Paris&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe></div></div>
                </div>
            </div>
        </div>
        <div class="container mx-auto py-12 text-center">
            <div class="py-6"><h3 className='text-center font-semibold text-2xl'>Nos partenaires</h3></div>
            <p class="text-center">Une question pour un futur achat ou un problème sur un matériel ? </p>
            <div class="my-6">
                <Link to='/faq' className='text-center bg-black text-white  py-2 px-4 rounded-lg'>Consulter la foire aux questions</Link>
            </div>
        </div>
        <FooterComponent />
    </>
  )
}

export default HomePage