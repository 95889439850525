import React, { useEffect, useState } from 'react'
import HeaderComponent from './headerComponent';
import { useParams } from 'react-router-dom';
import FooterComponent from './footerComponent';


const BlogArticlePage = () => {


    const { blogArticleId } = useParams();
    const [article, setArticle] = useState({});

    const getArticle = async () => {
        const response = await fetch(process.env.REACT_APP_DIRECTUS_URL + 'items/blog/'+blogArticleId, {referrerPolicy: "unsafe-url" });
        const data = await response.json();
        console.log(data.data)
        setArticle(data.data);
    }

    useEffect(() => {
        getArticle()
    }, []);

  return (
    <>
        <HeaderComponent />
        <div className="container mx-auto py-12" >
            <div className='h-[400px] flex items-center justify-center bg-cover rounded-lg' style={{backgroundImage: article.image_presentation ? `url(${process.env.REACT_APP_DIRECTUS_URL + 'assets/'+article.image_presentation})` : '', boxShadow: 'inset 0 0 0 2000px rgba(35, 35, 35, 0.3)'}}>
                <h1 className='text-center font-semibold text-3xl text-white'>{article.article_name}</h1>
            </div>
            <div className='py-12'>

                <article>
                    <div dangerouslySetInnerHTML={{ __html:article.blog_content }} />
                </article>
                
            </div>
        </div>
        <FooterComponent />
    </>
  )
}

export default BlogArticlePage